import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DvrIcon from '@material-ui/icons/Dvr';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import StoreIcon from '@material-ui/icons/Store';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import UpdateIcon from '@material-ui/icons/Update';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  MonetizationOnIcon: MonetizationOnIcon,
  DesktopWindowsIcon: DesktopWindowsIcon,
  DvrIcon: DvrIcon,
  AccountBalanceWalletIcon: AccountBalanceWalletIcon,
  SendIcon: SendIcon,
  TrendingDownIcon: TrendingDownIcon,
  AttachMoneyIcon: AttachMoneyIcon,
  StoreIcon: StoreIcon,
  BurstModeIcon: BurstModeIcon,
  LiveHelpIcon: LiveHelpIcon,
  PhoneIphoneIcon: PhoneIphoneIcon,
  UpdateIcon: UpdateIcon,
  NotInterestedIcon: NotInterestedIcon,
  VerifiedUserIcon: VerifiedUserIcon,
  MonetizationOnIcon:MonetizationOnIcon,
  DesktopWindowsIcon:DesktopWindowsIcon,
  DvrIcon:DvrIcon,
  AccountBalanceWalletIcon:AccountBalanceWalletIcon,
  SendIcon:SendIcon,
  TrendingDownIcon:TrendingDownIcon,
  AttachMoneyIcon:AttachMoneyIcon
};

//clerck menu

export default [
  {
    label: 'Menu',
    content: JSON.parse(
      `[
        {
          "label": "Lottery Settings",
          "icon": "SettingsIcon",
          "content":[
            {
              "label":"Results",
              "icon":"",
              "description":"N/A",
              "to":"/Lottery/results/entry"
            },
            {
              "label":"Results Validation",
              "icon":"",
              "description":"N/A",
              "to":"/Lottery/results/validation"
            }
          ]
        },
        {
          "label": "3Wa Settings",
          "icon": "SettingsIcon",
          "content":[
            {
              "label":"3Wa Results Validation",
              "icon":"",
              "description":"N/A",
              "to":"/Lottery/combolottery/results/validation"
            }
          ]
        },
      {
        "label": "Pick 2 Settings",
        "icon": "SettingsIcon",
        "content":[
          {
            "label":"Results",
            "icon":"",
            "description":"N/A",
            "to":"/Lottery/pick2/results/entry"
          },
          {
            "label":"Results Validation",
            "icon":"",
            "description":"N/A",
            "to":"/Lottery/pick2/results/validation"
          }
        ]
      },
      {
        "label": "Ghana Game Settings",
        "icon": "SettingsIcon",
        "content":[
          {
            "label":"Results",
            "icon":"",
            "description":"N/A",
            "to":"/ghanagame/results/entry"
          },
          {
            "label":"Results Validation",
            "icon":"",
            "description":"N/A",
            "to":"/ghanagame/results/validation"
          }
        ]
      },
      {
        "label": "Megamillion Settings",
        "icon": "SettingsIcon",
        "content":[
          {
            "label":"Results",
            "icon":"",
            "description":"N/A",
            "to":"/megamillion/results/entry"
          },
          {
            "label":"Results Validation",
            "icon":"",
            "description":"N/A",
            "to":"/megamillion/results/validation"
          }
        ]
      },
       {
    "label": "User Follow Up",
    "description": "N/A",
    "icon":"Search",
    "to": "/followup/list"
  },
      {
        "label": "User List",
        "description": "N/A",
        "icon":"Search",
        "to": "/User/search"
      },
      {
        "label": "Online Users",
        "icon":"PersonIcon",
        "description": "N/A",
        "to": "/User/list/4"
      },
      {
    "label": "User Pending Verification",
    "icon":"VerifiedUserIcon",
    "description": "N/A",
    "to": "/User/verify/request"
  },
      {
        "label": "Street Users",
        "icon":"PeopleIcon",
        "content": [
          {
            "label": "Directors",
            "description": "N/A",
            "to": "/User/list/1"
          },
          {
            "label": "Managers",
            "description": "N/A",
            "to": "/User/list/6"
          },
          {
            "label": "Vande Mobil",
            "description": "N/A",
            "to": "/User/list/3"
          },
          {
            "label": "Sub Vendor",
            "description": "N/A",
            "to": "/User/list/2"
          }
        ]
      },
      {
        "label": "Transactions Reports",
        "icon":"ReceiptIcon",
        "content" :
        [
          {
          "label":"User's Report",
          "icon":"",
          "description":"N/A",
          "to":"/Reports/Transactions/VM"
          },
          {
            "label":"Lottery Ticket Report",
            "icon":"",
            "description":"N/A",
            "to":"/Lottery/tickets"
          },
          {
            "label":"3Wa Lottery Ticket Report",
            "icon":"",
            "description":"N/A",
            "to":"/Lottery/combogame/tickets"
          },
          {
            "label":"Pick2 Ticket Report",
            "icon":"",
            "description":"N/A",
            "to":"/Lottery/pick2/tickets"
          },
          {
            "label":"Ghana Game Tickets Report",
            "icon":"",
            "description":"N/A",
            "to":"/ghanagame/tickets"
          },
          {
            "label":"Mega Million Ticket Report",
            "icon":"",
            "description":"N/A",
            "to":"/Megamillion/list"
          },{
          "label":"Jackpot Tickets report",
          "icon":"",
          "description":"N/A",
          "to":"/Reports/Jackpot-Tickets-Report"
        },

          {
          "label":"Raffle Tickets Reports",
          "icon":"",
          "description":"N/A",
          "to":"/Reports/Raffle-Tickets-Report"
        }
        ]
      }
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];


/*
  {
    "label": "Overview",
    "icon": "DashboardTwoToneIcon",
    "to": "/Overview"
  },
*/
